import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import "../Logistics/Logistics.scss";
import ServiceToolbar from "../ServiceToolbar";

const CargoDelivery = () => {
  return (
    <>
      <div className="logistics-con">
     <div>
      <p className="title">
        <StaticImage
          className="service-badge"
          src="../../../images/svg/cargo-delivery.svg"
          width={45}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="badge"
        />
        Cargo Delivery
        </p>
     </div>

        <div className="d-flex flex-row service-ch-land">
          <div className="logistics-left">
            <div className="logistics">
              <StaticImage
                  className="ch-img"
                  src="../../../images/cargo-ship-2.jpeg"
                  width={400}
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="about us"
                />

              <div className="text">
                {/* <p className="label">Stevedoring & Arrastre</p> */}
                <p>
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. */}
                </p>
                <br/>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ServiceToolbar />
    </>
  );
};

export default CargoDelivery;
